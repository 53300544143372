import {
  countryList,
  localPaymentSupportCountryList
} from '../data/CoursePage/wisenetMapCountryList';

import { PhoneNumberUtil } from 'google-libphonenumber';

export const INPUT_FIELD_TYPE = 'input';
export const NEW_INPUT_FIELD_TYPE = 'new-input';
export const INPUT_WITH_SUGGESTION_FIELD_TYPE = 'inputWithSuggestions';
export const DATE_FIELD_TYPE = 'date';
export const DROPDOWN_FIELD_TYPE = 'select';
export const HOST_DROPDOWN_FIELD_TYPE = 'hostSelect';
export const UPLOAD_IMAGE_FIELD_TYPE = 'uploadImage';
export const CHECKBOX_FIELD_TYPE = 'checkbox';
export const TEXTAREA_FIELD_TYPE = 'textarea';
export const RADIO_FIELD_TYPE = 'radio';
export const INLINE_RADIO_FIELD_TYPE = 'inline-styled-radio';
export const PHONE_NUMBER_WITH_DIAL_CODE = 'phoneNumberWithDialCode';

export const countryOptions = countryList.map(({ name, id }) => ({
  value: id,
  label: name
}));

export const supportedLocalPaymentCountryOptions =
  localPaymentSupportCountryList.map(({ name, id }) => ({
    value: id,
    label: name
  }));

export const dialCodeOptions = [...countryList]
  .sort((a, b) => a.name.localeCompare(b.name))
  .map(({ id, name, dialCode, flag }) => ({
    value: id,
    label: `${flag} ${dialCode}`,
    optionsDisplay: ` ${name} : ${flag ?? ''} (${dialCode})`,
    dialCode: dialCode
  }));

export const dialCodeOptionsWithoutFlag = [...countryList]
  .sort((a, b) => a.name.localeCompare(b.name))
  .map(({ id, name, dialCode, flag }) => ({
    value: id,
    label: `${dialCode}`,
    optionsDisplay: ` ${name} : ${flag} (${dialCode})`,
    dialCode: dialCode
  }));

export const dialCodeOptionsWithoutCountryName = [...countryList]
  .sort((a, b) => a.name.localeCompare(b.name))
  // eslint-disable-next-line no-unused-vars
  .map(({ id, name, dialCode }) => ({
    value: id,
    label: `${dialCode}`
  }));

export const getCountryById = (countryId) => {
  const country = countryList.find(
    ({ id }) => id === parseInt(countryId, 10)
  );
  return country;
};

export const getCountryIdByName = (countryName) => {
  return countryList.find(({ name }) => name === countryName);
};

export const getCountryNameById = (countryId) => {
  const country = countryList.find(
    ({ id }) => id === parseInt(countryId, 10)
  );
  return country?.name || '';
};

export const getDialCodeById = (countryId) => {
  const country = countryList.find(
    ({ id }) => id === parseInt(countryId, 10)
  );
  return country?.dialCode || '';
};

/**
 * Return the id of the country object
 *
 * @param {string} dial //+65
 * @returns {number} //153
 */
export const getDialCodeIdByDialCode = (dial) => {
  const country = countryList.find(({ dialCode }) => dialCode === dial);
  return country?.id?.toString() ? country?.id : ''; // id - undefined or null
};

/**
 * Extract the country id from phone number
 *
 * @param {string} phoneNumber //+65 87932774
 * @returns {number} //153
 */
export const getDialCodeIdFromPhoneNumber = (phoneNumber) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parse(phoneNumber);
    const dialCode = parsedNumber.getCountryCode();
    return getDialCodeIdByDialCode(`+${dialCode}`);
  } catch (error) {
    return '';
  }
};

export const getPhoneNumberWithoutDial = (phoneNumber) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parse(phoneNumber);
    return parsedNumber.getNationalNumber();
  } catch (error) {
    return '';
  }
};

export const getPhoneNumberAndDialCodeSegregated = (phoneNumber) => {
  try {
    if (!phoneNumber.startsWith('+')) {
      phoneNumber = `+${phoneNumber}`;
    }
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parse(phoneNumber);
    const dialCode = parsedNumber.getCountryCode();
    const phoneNumberWithoutDialCode = parsedNumber.getNationalNumber();
    return { dialCode: `+${dialCode}`, phoneNumberWithoutDialCode };
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const validatePhoneNumber = (phoneNumber) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const rawPhoneNumber = phoneUtil.parseAndKeepRawInput(phoneNumber);
    return phoneUtil.isValidNumber(rawPhoneNumber);
  } catch (error) {
    return false;
  }
};

export const validateEmail = (email) => {
  if (!email) {
    return false;
  }

  const regex = /^[\w-+.]+@([\w-]+\.)+[\w-]{2,}$/;

  return regex.test(String(email).toLowerCase());
};

export const convertToBool = (value) => (value === 'on' ? true : false);
