import * as yup from 'yup';

/* eslint-disable no-control-regex */
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

import { getDialCodeById } from './formHelpers';

export const urlWithoutProtocolRegex =
  // eslint-disable-next-line no-useless-escape
  /^(((https?|ftp):)?\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

export const isStrongPassword = (str) => {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
};

export const isValidEmailFormat = (email) => {
  const re =
    /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
  return re.test(email);
};

export const isValidPhoneFormat = (value) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    const number = phoneUtil.parseAndKeepRawInput(value);
    return !!phoneUtil.isValidNumber(number);
  } catch (e) {
    return false;
  }
};

export const getFormattedPhone = function (value) {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const number = phoneUtil.parseAndKeepRawInput(value);
  return phoneUtil.format(number, PhoneNumberFormat.E164);
};

export const isOnlyDigit = function (value) {
  return /^\d+$/.test(value);
};

export const validateOptionalPhoneNumberWithDialCode = (
  value,
  dialCodeId
) => {
  // Null is important to be thruthy for the email to allow for the not required property
  if (!value || value.length === 0) {
    return true;
  }

  if (value.includes('+')) {
    return true;
  }

  if (!dialCodeId) {
    return true;
  }

  if (dialCodeId) {
    const dialCode = getDialCodeById(dialCodeId);
    const fullPhoneNumber = `${dialCode}${value}`;
    return isValidPhoneFormat(fullPhoneNumber);
  }
  return false;
};

// export const isValidWebLinkWithoutHTTP = function (value) {
//   const re = new RegExp(
//     '[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
//     'i'
//   );
//   return re.test(value);
// };

// a more simple regex
// Valid Url : [
//     'ggogle.com',
//     'nas.academy',
//     'http://google.com',
//     'www.nas.academy',
// ]
export const isValidWebLinkWithoutHTTP = function (value) {
  const re = new RegExp(
    '(http(s)?://)?([\\w-]+\\.)+[\\w-]+(/[\\w- ;,./?%&=]*)?',
    'i'
  );
  return re.test(value);
};

export const isValidWebLinkWithHTTP = function (value) {
  const re = new RegExp(
    'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
    'i'
  );
  return re.test(value);
};

export const validate = async ({ schema, formData, setErrors }) => {
  setErrors({});

  let isValid = await schema
    .validate(formData, {
      abortEarly: false
    })
    .then(() => true)
    .catch((validationErr) => {
      if (Array.isArray(validationErr.inner)) {
        let errors = {};
        validationErr.inner.forEach(
          ({ path, message }) => (errors[path] = message)
        );
        setErrors(errors);
      }
      return false;
    });

  return isValid;
};

// TODO : Maybe move this to a utils file
// https://stackoverflow.com/questions/56725383/create-dynamic-yup-validation-schema-from-json
export const createYupSchema = (schema, config) => {
  const {
    label,
    inputSectionKey,
    fieldName,
    isRequired = false,
    isDeleted,
    isSunlightUrl
  } = config;

  //if the field is deleted from schema then do not include it in validation
  if (isDeleted) return schema;

  let validator = null;
  switch (inputSectionKey) {
    case 'text':
      validator = yup.string();
      if (isSunlightUrl) {
        validator = validator.matches(
          urlWithoutProtocolRegex,
          `${label} should be a valid url`
        );
      }
      if (isRequired) {
        validator = validator.required(`${label} is required`);
      }
      break;
    case 'phone':
      validator = yup.string();
      if (isRequired) {
        validator = validator.required(`${label} is required`);
      }
      validator = validator.test(
        'phoneNumberValidation',
        'Invalid Phone Number',
        (value) => isValidPhoneFormat(value)
      );
      break;
    case 'email':
      validator = yup.string().email(`${label} must be a valid email`);
      if (isRequired) {
        validator = validator.required(`${label} is required`);
      }
      break;
    case 'url':
      validator = yup.string().url(`${label} should be a valid url`);
      if (isRequired) {
        validator = validator.required(`${label} is required`);
      }
      break;
    case 'number':
      validator = yup.number(`${label} must be a number`);
      if (isRequired) {
        validator = validator.required(`${label} is required`);
      }
      break;
    case 'date':
      validator = yup.date(`${label} should be a valid date`);
      if (isRequired) {
        validator = validator.required(`${label} is required`);
      }
      break;
    case 'checkbox':
      validator = yup.object();
      if (isRequired) {
        validator = validator.required(`${label} is required`);
        validator = validator.test(
          'groupCheckboxValidation',
          'Please select atleast 1',
          (value) => {
            return !!Object.values(value).find(Boolean);
          }
        );
      }
      break;
    case 'multi-select-modal':
      validator = yup.object();
      if (isRequired) {
        validator = validator.test(
          'groupCheckboxValidation',
          'Please select atleast 1',
          (value) => {
            return !!Object.values(value).find(Boolean);
          }
        );
      }
      break;
    default:
      // do nothing
      break;
  }

  if (!validator) {
    return schema;
  }

  schema[fieldName] = validator;
  return schema;
};
